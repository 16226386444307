.basic-layout-wrap {
  background-color: #fff;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  &.basic-gray {
    background-color: #f8f8f7;
  }
}

@brand-primary: #0673D4;@color-text-base: rgba(0,0,0,0.75);