.page-loading {
  position: fixed;
  z-index: 999;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  background-color: #fff;
  img {
    height: 1.32rem;
    margin-bottom: 0.4rem;
  }
}

@brand-primary: #0673D4;@color-text-base: rgba(0,0,0,0.75);