a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
button,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
input,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
select,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
textarea,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  word-break: break-all;
}

html {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: PingFangSC-Regular, PingFang SC;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.75);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-touch-callout: none;
  user-select: none;
  -webkit-user-select: none;
  input,
  textarea {
    -webkit-touch-callout: inherit;
    user-select: all;
    -webkit-user-select: all;
  }
  &.noFix {
    position: relative;
    overflow: auto;
    width: 100%;
    height: auto;
  }
}

#root {
  overflow-x: hidden;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  margin: 0;
  padding: 0;
}

code {
  font-family: Monaco, Menlo, Ubuntu Mono, Consolas, source-code-pro, monospace;
}

.elli_2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /*指定行数，溢出则隐藏*/
  overflow: hidden;
  text-overflow: ellipsis;
}

.pointer {
  cursor: pointer;
}

.flex {
  display: flex;
}

/*垂直方向 排序*/
.flex-v {
  flex-direction: column;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-wrap {
  flex-wrap: wrap;
}

/*交叉轴对齐方向 */
.flex-align-center {
  align-items: center;
}

/*左对齐*/
.flex-align-top {
  align-items: flex-start;
}

/*右对齐*/
.flex-align-bottom {
  align-items: flex-end;
}

/*居中对齐*/
.flex-pack-center {
  justify-content: center;
}

.flex-pack-end {
  justify-content: flex-end;
}

.flex-pack-start {
  justify-content: flex-start;
}

/*分散对齐*/
.flex-pack-justify {
  justify-content: space-between;
}

/*距离盒子两边间距相同*/
.flex-pack-around {
  justify-content: space-around;
}

.menuLabel {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    transform: scaleY(0.0001);
    opacity: 0;
    border-left: 4px solid rgba(58, 125, 255, 1);
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
      opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  &:hover {
    background: rgba(245, 248, 255, 1);
  }

  &.active {
    background: rgba(245, 248, 255, 1);
    &::after {
      content: '';
      transform: scaleY(1);
      opacity: 1;
      transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
        opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }
}

body {
  .am-button {
    height: 0.8rem;
    line-height: 0.8rem;
    font-size: 0.28rem;
    padding: 0 0.32rem;
    & + .am-button {
      margin-left: 0.16rem;
    }
    &.am-button-small {
      font-size: 0.24rem;
      height: auto;
      padding: 0.04rem 0.16rem;
      line-height: normal;
    }
  }
}

@brand-primary: #0673D4;@color-text-base: rgba(0,0,0,0.75);