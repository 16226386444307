.order {
  .mallBox1 {
    padding: 0 0.32rem;
    height: 1.52rem;
    color: #fff;
    background: linear-gradient(242deg, rgba(0, 91, 172, 0.99) 0%, #3f91d8 100%);
  }
  .pointsNum {
    font-size: 0.4rem;
    line-height: 0.54rem;
    margin-right: 0.2rem;
  }
  .pointsBill {
    width: 1.12rem;
    height: 0.4rem;
    background-color: rgba(4, 76, 141, 0.82);
    border-radius: 0.96rem;
    font-size: 0.2rem;
    span {
      transform: scale(0.9);
    }
  }
}
.tabBox {
  padding: 0.3rem 0.32rem;
}
.goodsCard {
  width: 6.86rem;
  border-radius: 0.08rem;
  box-shadow: 0px 0.08rem 0.16rem rgba(0, 0, 0, 0.08);
  padding: 0.24rem 0.24rem 0.32rem 0.3rem;
  margin-bottom: 0.3rem;
  overflow: hidden;
  .rightBox {
    font-size: 0.24rem;
    line-height: 0.34rem;
    color: #5b5e63;
  }
  .goodsCode {
    font-size: 0.24rem;
    font-weight: bold;
    line-height: 0.34rem;
    color: #06121e;
    margin-bottom: 0.04rem;
  }
  .goodsTitle {
    font-size: 0.28rem;
    font-weight: bold;
    line-height: 0.4rem;
    color: #06121e;
  }
  .goodsBox {
    padding: 0 0.24rem 0 0.18rem;
  }
  .goodsCover {
    width: 1.56rem;
    height: 1.56rem;
    border-radius: 0.06rem;
  }
  .goodsTitle {
    font-size: 0.28rem;
    line-height: 0.4rem;
    color: #3d444d;
    font-weight: bold;
    margin-bottom: 0.04rem;
  }
  .goodsNum {
    font-size: 0.24rem;
    line-height: 0.34rem;
    color: #c0c5cc;
  }
}

@brand-primary: #0673D4;@color-text-base: rgba(0,0,0,0.75);